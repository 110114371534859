import {Card} from "antd";
import {useNavigate} from "react-router-dom";


function QuizItem({id,number,RQuizes}){
    let navigate = useNavigate()
    const handleOpenTest = (id)=>{
        window.location.href = `/quizes/${id}`;
    }
    return(<Card.Grid onClick={()=>handleOpenTest(id)} className={'quiz-grid '+(RQuizes[id]?RQuizes[id]>17?'quiz-green':'quiz-red':'')}  >
            Թեստ N{number}
            <div>{RQuizes[id]??'-'}/20</div>
    </Card.Grid>
    )
}
export default QuizItem;