import {Layout} from "antd";
import {Route,Routes} from "react-router-dom";
import Home from "./Home";
import Quizes from "./Quiz/Quizes";
import AppHeader from "./Fragments/AppHeader";
import Quiz from "./Quiz/Quiz";
import Wrongs from "./Wrongs/Wrongs";
import Categories from "./Categories/Categories";
import Category from "./Categories/Category";
import Descriptions from "./Descriptions/Descriptions";
import ReactGA from 'react-ga';
import {useSelector} from "react-redux";
import Descs from "./Descriptions/Descs";
const TRACKING_ID = "UA-235056627-1";

ReactGA.initialize(TRACKING_ID);
const { Content, Footer } = Layout;

function AppLayout(){
   let {settings} =  useSelector((data) => data)
   const startAnalytics = ()=>{
       ReactGA.pageview(window.location.pathname + window.location.search);
   }
return(
    <Layout className="layout">
        <AppHeader/>
        <Content>
            <div className="site-layout-content">
                <Routes>
                    <Route path="/" element={<Home analytics={startAnalytics}/>} />
                    <Route path="quizes" element={<Quizes analytics={startAnalytics}/>} />
                    <Route path="quizes/:id" element={<Quiz analytics={startAnalytics}/>} />
                    <Route path="wrongs" element={<Wrongs analytics={startAnalytics}/>} />
                    <Route path="categories" element={<Categories analytics={startAnalytics}/>} />
                    <Route path="categories/:id" element={<Category analytics={startAnalytics}/>} />
                    <Route path="descriptions/" element={<Descriptions analytics={startAnalytics}/>} />
                    <Route path="definitions/" element={<Descs analytics={startAnalytics}/>} />
                </Routes>
            </div>
        </Content>
        <Footer style={{ textAlign: 'center' }}>AvtoDas ©2022 Created by AvtoDas V{settings.app_version}</Footer>
    </Layout>
)
}
export default AppLayout;