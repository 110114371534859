import {useDispatch, useSelector} from "react-redux";
import {Button, Spin} from "antd";
import api from "../api";
import {useEffect, useState} from "react";
import {handleQuizStat} from "../functions";
import QuestionItem from "../Quiz/Fragments/QuestionItem";
import MetaTags from "react-meta-tags";
import {Trans, useTranslation} from "react-i18next";

function Wrongs({analytics}){
    const [loading,setLoading] = useState(false);
    const [question,setQuestion] = useState(null);
    const [t,i18n] = useTranslation();
    const [answered,setAnswered] = useState(false);
    const dispatch = useDispatch();
    let state = useSelector((state)=>state)
    let wrongs = state.questions
    let settings = state.settings
    useEffect(()=>{
        analytics()
        fetchQuestion()

    },[])
    const fetchQuestion = ()=>{
        setAnswered(false)
        setQuestion(null)
        if(wrongs.length){
        setLoading(true)
            api.API.get(api.Question+`/${wrongs[Math.floor(Math.random()*wrongs.length)]}`).then(question=>{
                setQuestion(question)
                setLoading(false)
            })
        }
    }
    const handleNext = (variant)=>{
        handleQuizStat(question.id,variant===question.answer)
        if(variant===question.answer){



            dispatch({
                payload:question.id,
                type:'QESTION_PASSED'
            })
        }
      setAnswered(variant)
    }
    return (
        <div>
            <MetaTags>
                <title>AvtoDas - Սխալներ</title>
                <meta name="description" content="Վարորդական տեսական քննության թեստեր,բացատրություններով անվճար։Բոլոր նոր թեստերը 1200 հարց տեսական քննություն" />
                <meta property="og:title" content="AvtoDas - Սխալ պատասխաններ" />
                <meta property="og:image" content="/logo.png" />
            </MetaTags>
            <h1>{t('wrongs.desc_1')}</h1>
            <h4>{t('wrongs.desc_2',{
                count:wrongs.length
            })}</h4>
            <Spin spinning={loading}>
                {question?<div>
                    <QuestionItem quick_view={true} currentQuestion={question} handleNext={handleNext} value={answered} noSubmit={answered!==false}/>
                    <div style={{textAlign:'center'}}>
                        {answered!==false? <Button htmlType={'button'} onClick={fetchQuestion} type={'primary'}><Trans i18nKey={'questions.next'}>Հաջորդը</Trans> </Button>: null}
                    </div>

                </div>:null}
            </Spin>

        </div>
    )

}
export default Wrongs