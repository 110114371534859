export default function quizes(state = {}, action) {
    if (action.type === 'QUIZ_PASSED') {
        return {
            ...state,
            ...action.payload,
        };
    }
    if (action.type === 'SYNC') {

        return [
            ...action.payload.questions
        ];
    }
    if (action.type === 'CLEAR_ANSWERS') {

        return {

        };
    }
    return state;
}