import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    .use(LanguageDetector)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        debug: true,
        fallbackLng: 'hy',
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },
        resources: {
            ru: {
                translation: {
                    header:{
                        home:'Главная',
                        tests:'Тесты',
                        wrongs:'Неправельные ответы',
                        grouped_questions:'Категории вопросов',
                    },
                    home: {
                        welcome_text: 'Добро пожаловать AvtoDas.am',
                        description:"AvtoDas.am включает в себя все вопросы теоретического теста для получения водительских прав, совершенно бесплатно. Здесь вы можете ознакомиться с тестами, ответить на заданные вопросы, а затем, увидев результаты, еще раз ответить на неправильно отвеченные вопросы, что поможет закрепить правильные ответы.На сайте также есть список вопросов, сгруппированных по темам. После прохождения тестов обратите особое внимание на данные пояснения.",
                        results:"Статистика",
                        questions_count:'Количество вопросов',
                        answered:'Ответили',
                        true_answers:'Правильные ответы',
                        wrong_answers:'Неправильные ответы',
                        answer:'Отвечать',
                        not_answered:'Вопросы без ответов',
                        see_answer_end:'Смотреть результат в конце',
                        see_answer_end_desc:'После ответа на вопрос автоматически переходить к следующему и смотреть результаты в конце',
                        site_lang:'Язык веб-сайта',
                        settings:'Настройки',
                    },
                    wrongs:{
                        desc_1:'Здесь вы можете решать вопросы, на которые вы ответили неправильно',
                        desc_2:'Неправильные ответы - {{count}}',
                    },
                    questions:{
                        see_description:'Դիտել բացատրությունը',
                        next:'Следующий',
                        tests:'Тесты',
                        true_answers_count:'Количество правильных ответов {{count}}/20',
                        difficulty:'Сложность',

                    }
                }
            },
            hy: {
                translation: {
                    header:{
                        home:'Գլխավոր',
                        tests:'Թեստեր',
                        wrongs:'Սխալ պատասխաններ',
                        grouped_questions:'Խմբավորված հարցեր',
                    },
                    home:{
                        welcome_text:'Բարի գալուստ AvtoDas.am',
                        description:"AvtoDas.am -ում ներառված են վարորդական իրավունք ստանալու տեսական քննության բոլոր հարցերը՝ բոլորովին անվճար։ Այստեղ Դուք կարող եք ծանոթանալ թեստերի հետ, պատասխանել տրված հարցերին , այնուհետև տեսնելով արդյունքները, կրկին պատասխանել սխալ պատասխանված հարցերին,որը կօգնի ամրապնդել ճիշտ պատասխանները։ Կայքում կան նաև խմբավորված հարցերի ցանկ՝ ըստ թեմաների։ Թեստերը անցնելուց հետո հատուկ ուշադրություն դարձրեք տրված բացատրություններին ։",
                        results:"Արդյունքների ամփոփում",
                        questions_count:'Հարցերի քանակը',
                        answered:'Փորձել եք պատասխանել',
                        true_answers:'Ճիշտ պատասխաններ',
                        wrong_answers:'Սխալ պատասխաններ',
                        answer:'Պատասխանել',
                        not_answered:'Չպատասխանված հարցեր',
                        see_answer_end:'Տեսնել արդյունքը վերջում',
                        see_answer_end_desc:'Հարցը պատասխանելուց հետո ավտոմատ անցնել մյուսին և արդյունքները տեսնել վերջում',
                        site_lang:'Կայքի լեզուն',
                        settings:'Կարգավորումներ',
                    },
                    wrongs:{
                        desc_1:'Այստեղ կարող եք լուծել ձեր սխալ պատասխանած հարցերը',
                        desc_2:'Սխալ պատասխաններ - {{count}}',
                    },
                    questions:{
                        see_description:'Դիտել բացատրությունը',
                        next:'Հաջորդը',
                        tests:'Հարցաշար',
                        true_answers_count:'Ճիշտ պատասխանների քանակը {{count}}/20',
                        difficulty:'Բարդությունը',
                    }


                }
            },
        }
    });

export default i18n;