
import {Button, Form, Image, Input, Radio, Space, Spin, Upload} from "antd";
import api from "../api";
import {useEffect, useState} from "react";

export default function Descriptions({analytics}){
    const [loading,setLoading] = useState(false);
    const [question,setQuestion] = useState(null);
    const [imageUrl, setImageUrl] = useState();
    useEffect(()=>{
        analytics()
        fetchQuestion()
    },[])
    const fetchQuestion = ()=>{
            setLoading(true)
            api.API.get(api.Question+`/no-desc`).then(question=>{
                setQuestion(question)
                setLoading(false)
            })

    }
   const handleSubmitDesc = (values) =>{
       const formData = new FormData();
       for (const name in values) {
           if(values[name] || values[name]===0){
               formData.append(name, values[name]); // there should be values.avatar which is a File object
           }

       }
       formData.append('id',question.id); // th
        api.API.post(api.Question+`/submit-desc`,formData).then(()=>{

            fetchQuestion()
        })
    }
    const dummyRequest = ({ file, onSuccess }) => {
        setTimeout(() => {
            onSuccess("ok");
        }, 0);
    };
    const beforeUpload = (file) => {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';

        const isLt2M = file.size / 1024 / 1024 < 2;

        return isJpgOrPng && isLt2M;
    };
    const getBase64 = (img, callback) => {
        const reader = new FileReader();
        reader.addEventListener('load', () => callback(reader.result));
        reader.readAsDataURL(img);
    };
    const handleChange = (info) => {
        if (info.file.status === 'uploading') {
            return;
        }

        if (info.file.status === 'done') {
            // Get this url from response in real world.
            getBase64(info.file.originFileObj, (url) => {
                setLoading(false);
                setImageUrl(url);
            });
        }
    };

    const uploadButton = (
        <div>
            {loading ? 'aaaaa' : 'bbbbbbbbbb'}
            <div
                style={{
                    marginTop: 8,
                }}
            >
                Upload
            </div>
        </div>
    );
    return (
        <div>
            {loading?<Spin/>: question?<div>
                    <h3>{question.question}</h3>
                    <div className={'image-block'}>
                        {question.image && <Image
                            className={'image-item'}
                            src={process.env.REACT_APP_API_ENDPOINT + question.image}
                        />}
                    </div>
                <Form onFinish={handleSubmitDesc}
                      initialValues={{variant: question.answer}}
                >
                        <Form.Item name={'variant'} rules={[
                            {
                                required: true,
                                message: 'Ընտրեք որևէ պատասխան'
                            }
                        ]}>
                            <Radio.Group name="variant">
                                <Space direction={'vertical'}>
                                    {question.variants.map((variant, key) => {
                                        return <Radio key={key} value={key}>{variant.value}</Radio>
                                    })}
                                </Space>

                            </Radio.Group>
                        </Form.Item>

                        <Form.Item name={'title'} label={'title'}>
                            <Input/>

                        </Form.Item>
                        <Form.Item
                            name="avatar"
                            label="Upload profile picture"
                            getValueFromEvent={({file}) => file.originFileObj}
                        >
                            <Upload
                                accept="image/png, image/jpeg"
                                maxCount={1}
                                listType="picture-card"
                                showUploadList={false}
                                customRequest={dummyRequest}
                                beforeUpload={beforeUpload}
                                onChange={handleChange}
                            >
                                {imageUrl ? <img src={imageUrl} alt="avatar" style={{ width: '100%' }} /> : uploadButton}
                            </Upload>
                        </Form.Item>
                        <Form.Item name={'description'} label={'desc'}>
                            <Input.TextArea />
                        </Form.Item>
                        <Button htmlType={'submit'}>Sumit</Button>
                    </Form>
                </div>:null}

        </div>
    )

}