
import {Button, Divider, Form, Image, Input, Radio, Space, Spin, Upload} from "antd";
import api from "../api";
import {useEffect, useState} from "react";
import QuestionItem from "../Quiz/Fragments/QuestionItem";

export default function Descs({analytics}){
    const [pass,setPass] = useState(false);
    const [data,setData] = useState([]);
    const [imageUrl, setImageUrl] = useState();
    useEffect(()=>{
        api.API.get(`${api.Question}/definitions`).then(data=>{
            setData(data.data)
        })
    },[])
   const handleConfirm = (item)=>{
        api.API.post(`${api.Question}/definitions/${item.id}`,{
            pass,
            ok:1
        })
    }
   const handleRej = (item)=>{
        api.API.post(`${api.Question}/definitions/${item.id}`,{
            pass,
            ok:0
        })
    }
    const handleChange = (item)=>{
      setPass(item.target.value)
    }
    return (
        <div>
            <Input onChange={handleChange}/>
            {data.map(item=>{
             return <div key={item.id}>
                    <div>{item.data.title}</div>
                    <div>{item.data.description}</div>
                 <Divider/>
                 <div>{item.question.description.desc_name}</div>
                 <div>{item.question.description.desc_text}</div>
                 <Button onClick={()=>handleConfirm(item)} type={'primary'}>OK</Button>
                 <Button onClick={()=>handleRej(item)} type={'secondary'}>Remove</Button>
                    <QuestionItem currentQuestion={item.question}/>
                </div>
            })}


        </div>
    )

}