import axios from "axios";

let API = axios.create({
    baseURL:  process.env.REACT_APP_API_ENDPOINT,
});
API.interceptors.response.use((response)=>{
    if(response.status===200){
        return response.data.data
    }
})
export default {
    Quize:'api/quizes',
    Question:'api/questions',
    API
}