import {NavLink} from "react-router-dom";
import Image from "../../assets/logo.png"
import { Trans } from 'react-i18next';
import {Avatar} from "antd";
import IG_image from "../../assets/icons/ig.png"
const MenuList = [
    {
        path:'/',
        name:<Trans i18nKey={'header.home'}>Գլխավոր</Trans>
    },
    {
        path:'/quizes',
        name:<Trans i18nKey={'header.tests'}>Թեստեր</Trans>
    },
    {
        path:'/wrongs',
        name:<Trans i18nKey={'header.wrongs'}>Սխալ պատասխաններ</Trans>
    },
    {
        path:'/categories',
        name:<Trans i18nKey={'header.grouped_questions'}>Խմբավորված հարցեր</Trans>
    },
]

function AppHeader(){
    return(
        <header className="header">
            <a href="/" className="logo" style={{background:"url('"+Image+"')"}}></a>

            <input className="menu-btn" type="checkbox" defaultChecked={true} id="menu-btn"/>
            <label className="menu-icon" htmlFor="menu-btn"><span className="navicon"></span></label>
            <ul className="menu">
                {MenuList.map(item =><li key={item.path}><a href={item.path}>{item.name}</a></li>)}
            </ul>
        </header>

    )
}
export default AppHeader;