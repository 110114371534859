export default function settings(state = {
    quick_view:false,
    app_version:1,
    points:0
}, action) {
    if (action.type === 'QUICK_VIEW') {

        return {
            ...state,
            quick_view: action.payload
        };
    }
    if (action.type === 'UPDATE_VERSION') {

        return {
            ...state,
            app_version: action.payload
        };
    }
    if (action.type === 'ADD_AD_POINTS') {

        return {
            ...state,
            points: (state.points??0) + action.payload
        };
    }
    if (action.type === 'RESET_POINTS') {

        return {
            ...state,
            points: 0
        };
    }
    return state;
}