import {useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import api from "../api";
import QuestionItem from "../Quiz/Fragments/QuestionItem";
import {Button, Spin} from "antd";
import {useDispatch} from "react-redux";


function Category({analytics}){
    const {id} = useParams()
    const [tagData,setTagData] = useState({})
    const [QN,setQN] = useState(0);
    const [loading,setLoading] = useState(true);
    const [value,setValue] = useState(null);
    const [answered,setAnswered] = useState(false);
    const dispatch = useDispatch();
    useEffect(()=>{
        analytics()
        api.API.get(`/api/tags/${id}`).then(tagData=>{
            setTagData(tagData)
            setLoading(false)
        })
    },[])
    let currentQuestion =tagData?.questions? tagData?.questions[QN]:null
    const handleSubmit = (variant) =>{
            if(currentQuestion.answer!==variant){
                dispatch({
                    payload:[currentQuestion.id],
                    type:'QUESTIONS_PASSED'
                })
            }else{
                dispatch({
                    payload:currentQuestion.id,
                    type:'QESTION_PASSED'
                })
            }
            setValue(variant)
            setAnswered(true)
        }
        const handleNext = ()=>{
            setAnswered(false)
            setValue(false)
        setQN(QN+1)
        }


    return(
        <Spin spinning={loading}>
            {tagData.name}
            {currentQuestion?<QuestionItem quick_view={true} currentQuestion={tagData?.questions[QN]} handleNext={handleSubmit} noSubmit={true} value={value}/>:null}
            <div style={{textAlign:'center'}}>
                {answered!==false? <Button htmlType={'button'} onClick={handleNext} type={'primary'}>Հաջորդ հարցը</Button>: null}
            </div>
        </Spin>
    )
}
export default Category;