import {useEffect, useState} from "react";
import api from "../api";
import {Card, Col, Row, Spin} from "antd";
import './quiz.sass';
import { useSelector} from "react-redux";
import QuizItem from "./Fragments/QuizItem";
import MetaTags from "react-meta-tags";
import {Trans} from "react-i18next";
function Quizes({analytics}){
    const [quizes,setQuizes] = useState([])
    const [loading,setLoading] = useState(true)
    const RQuizes = useSelector(({quizes})=>quizes)

    useEffect(()=>{
        analytics()
        api.API.get(api.Quize).then((data)=>{
           setQuizes(data)
            setLoading(false)

        })
    },[])


    return(<div>
        <MetaTags>
            <title>AvtoDas - Թեստեր</title>
            <meta name="description" content="Վարորդական տեսական քննության թեստեր,բացատրություններով անվճար։Բոլոր նոր թեստերը 1200 հարց տեսական քննություն" />
            <meta property="og:title" content="AvtoDas - Թեստեր" />
            <meta property="og:image" content="/logo.png" />
        </MetaTags>
        <Spin spinning={loading}>
            <Card title={<Trans i18nKey={'questions.tests'}>Հարցաշար</Trans> }>
                <Row>

                    {quizes?.map(i=><Col lg={6} md={8} sm={8} xs={12} key={i.id} ><QuizItem RQuizes={RQuizes} id={i.id} number={i.number}/></Col>)}
                </Row>

            </Card>
        </Spin>


    </div>)
}
export default Quizes;