import {useEffect, useState} from "react";
import api from "../api";
import {Card, Col, Row, Spin} from "antd";
import CategoryItem from "./Fragments/CategoryItem";
import MetaTags from "react-meta-tags";


function Categories({analytics}){
    const [categories,setCategories] = useState([])
    const [loading,setLoading] = useState(true)

    useEffect(()=> {
        analytics()
        api.API.get('/api/tags').then(data=>{
           setCategories(data)
            setLoading(false)
        })


    },[])



    return(
        <div>
            <MetaTags>
                <title>AvtoDas - Խմբավորված հարցեր</title>
                <meta name="description" content="Վարորդական տեսական քննության թեստեր,բացատրություններով անվճար։Բոլոր նոր թեստերը 1200 հարց տեսական քննություն" />
                <meta property="og:title" content="AvtoDas - Թեստեր" />
                <meta property="og:image" content="/logo.png" />
            </MetaTags>
            <Spin spinning={loading}>
                <Card title="Հարցաշար">
                    <Row>
                        {categories.map(i=><Col lg={6} md={8} sm={8} xs={12} key={i.id} ><CategoryItem id={i.id} name={i.name} count={i.questions_count}/></Col>)}
                    </Row>

                </Card>
            </Spin>


        </div>
    )
}
export default Categories;