import './App.css';
import 'antd/dist/antd.css';
import AppLayout from "./Components/AppLayout";
import {BrowserRouter} from "react-router-dom";


function App() {
  return (
      <BrowserRouter>
        <AppLayout/>
      </BrowserRouter>


  );
}

export default App;
