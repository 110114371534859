import {useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import api from "../api";
import {Alert, Col, Progress, Row, Spin,Typography} from "antd";
import {useForm} from "antd/es/form/Form";
import {useDispatch, useSelector} from "react-redux";
import QuestionItem from "./Fragments/QuestionItem";
import {handleQuizStat} from "../functions";
import ReactGA from "react-ga";
import {Trans} from "react-i18next";

function Quiz({analytics}) {
    const {id} = useParams()
    const [formRef] = useForm();
    const [quiz, setQuiz] = useState(null)
    const [step, setStep] = useState(0);
    const [points, setPoints] = useState(0);
    const [answers, setAnswers] = useState({});
    const [wrongAnswers, setWrongAnswers] = useState([]);
    const [loading, setLoading] = useState(true)
    const dispatch = useDispatch();
    const {settings} = useSelector(state=>state)


    useEffect(() => {
        analytics()
        api.API.get(api.Quize + `/${id}`).then(data => {
            setQuiz(data)
            setLoading(false)


        })
    }, [id])

    let currentQuestion = quiz ? quiz.questions[step] : null
    const handleNext = (variant) => {

        formRef.resetFields()
        let safe_points = points;
        handleQuizStat(currentQuestion.id,variant === currentQuestion.answer)
        setAnswers({
            ...answers,
            [step]: variant
        })

        if (variant === currentQuestion.answer) {
            safe_points++
            setPoints(safe_points)
        } else {
            setWrongAnswers([...wrongAnswers, currentQuestion.id])
        }
        if(step===19){
            ReactGA.event({
                category: 'Quiz',
                action: `passed-${id}-${safe_points}`,
            });
            dispatch({
                payload:{[id]:safe_points},
                type:'QUIZ_PASSED'
            })
            dispatch({
                payload:wrongAnswers,
                type:'QUESTIONS_PASSED'
            })
        }

        setStep(step + 1)
    }
    return (
        <Spin spinning={loading} style={{width: '100%'}}>
            <Row style={{width: '100%'}} gutter={[10, 10]}>
                <Col lg={24} md={24} sm={24} xs={24}>
                   <Typography.Title style={{textAlign:'center'}} level={4}>Թեստ N{quiz?.number}</Typography.Title>
                    <Progress percent={step * 5}/>
                </Col>
                <Col lg={24} md={24} sm={24} xs={24}>

                    {step === 20 ? <div>
                        <Alert message={<Trans i18nKey={'questions.true_answers_count'} count={points}>Ճիշտ պատասխանների
                            քանակը {points}/20</Trans>}
                               type={points === 20 ? 'success' : points >= 18 ? 'warning' : 'error'}/>
                        {quiz.questions.map((question, key) => {
                            return <QuestionItem dispatch={dispatch} value={answers[key]} currentQuestion={question}
                                                 noSubmit={true}/>;
                        })}
                    </div> : null}
                    {currentQuestion ?
                        <QuestionItem dispatch={dispatch} quick_view={settings.quick_view} formRef={formRef}
                                      handleNext={handleNext} currentQuestion={currentQuestion}/> : null}
                </Col>
            </Row>
        </Spin>
    )
}

export default Quiz;
