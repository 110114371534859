import {Card} from "antd";
import {useNavigate} from "react-router-dom";


function CategoryItem({id,name,count}){
    let navigate = useNavigate()
    const handleOpenTest = (id)=>{
        navigate(`/categories/${id}`)
    }
    return(<Card.Grid onClick={()=>handleOpenTest(id)} className={'quiz-grid'}  >
                {name}
            <div>{count}</div>
        </Card.Grid>
    )
}
export default CategoryItem;