export default function questions(state = [], action) {
    if (action.type === 'QUESTIONS_PASSED') {
        let newData = [ ...state, ...action.payload].filter((value, index, self)=> self.indexOf(value) === index)
        return newData;
    }
    if (action.type === 'QESTION_PASSED') {

        return [
            ...state.filter(i=>i!==action.payload)
        ];
    }
    if (action.type === 'CLEAR_WRONGS') {

        return [
        ];
    }

    return state;
}