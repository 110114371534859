import {Tooltip} from "antd";
import {Trans} from "react-i18next";

export function QuestionStats({currentQuestion}){
    let status= 0;
    let {trues,wrongs} = currentQuestion;
    let color = 'green';

    if(trues){
        let all = wrongs+trues;
        status =Math.floor(wrongs*10/all)
    }
    if(status>6){
        color = 'red'
    }

    return <div className={'text-center text-16'}>
        <Tooltip title="Ցույց է տալիս այլ անձանց արդյունքները ճիշտ-սխալ պատասխանների քանակը տվյալ հարցին">
            <div style={{color}}>
                <Trans i18nKey={'questions.difficulty'}>Բարդությունը</Trans> - {status}/10
            </div>
        </Tooltip>
    </div>
}