import {Avatar, Button, Card, Col, Form, Popconfirm, Row, Switch, Timeline, Typography} from "antd";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {useEffect} from "react";
import Settings from "../settings"
import MetaTags from 'react-meta-tags';
import ReactGA from "react-ga";
import {useTranslation} from "react-i18next";
import IG_image from "../assets/icons/ig.png";


function Home({analytics}) {
    const [t, i18n] = useTranslation();
    let {quizes, questions, settings} = useSelector((data) => data)
    let passedCount = Object.values(quizes).length
    let navigate = useNavigate();
    const dispatch = useDispatch()
    const trueAnswers = (passedCount * 20) - questions.length;
    useEffect(analytics, [])
    useEffect(() => {
        if (settings.app_version !== Settings.app_version) {
            caches.keys().then(keys => Promise.all(keys.map(key => {
                return caches.delete(key);
            }))).then(() => {
                dispatch({
                    payload: Settings.app_version, type: 'UPDATE_VERSION'
                })
            })
        }

    }, [])
    const onSwitchChange = (value) => {
        ReactGA.event({
            category: 'Config', action: 'fast_view', value: value ? 1 : 0
        });
        dispatch({
            payload: value, type: 'QUICK_VIEW'
        })
    }
    const handleLangChange = (value) => {
        i18n.changeLanguage(value ? 'hy' : 'ru')
        window.location.reload(true)
    }
    const handleClearWrongs = () => {
        dispatch({
            payload: Settings.app_version, type: 'CLEAR_WRONGS'
        })
    }
    const handleClearAnswers = () => {
        dispatch({
            type: 'CLEAR_ANSWERS'
        })
    }

    return (<Row>
        <MetaTags>
            <title>AvtoDas - Վարորդական տեսական քննություններ</title>
            <meta name="description"
                  content="Վարորդական տեսական քննության թեստեր,բացատրություններով անվճար։Բոլոր նոր թեստերը 1200 հարց տեսական քննություն"/>
            <meta property="og:title" content="AvtoDas - Վարորդական տեսական Քննություն"/>
            <meta property="og:image" content="/logo.png"/>
        </MetaTags>
        <Col lg={24}>
            <Typography.Title level={3} className={'text-center'}>{t('home.welcome_text')}</Typography.Title>
        </Col>
        <Col lg={24}>
            <Card>
                <Typography.Text>
                    {t('home.description')}
                    <Typography.Title level={4}>Խնդիրների դեպքում դիմել-> <a
                        href={'https://www.instagram.com/avtodas.am/'} rel="noreferrer" target={'_blank'}> <Avatar
                        src={IG_image}/></a></Typography.Title>


                </Typography.Text>
            </Card>
        </Col>

        <Col lg={24}>
            <Card title={t('home.results')} style={{marginTop: 15}}>
                <Row>
                    <Col lg={12}>
                        <Timeline>
                            <Timeline.Item color="#00CCFF">{t('home.questions_count')} - 1200</Timeline.Item>
                            <Timeline.Item color="#00CCFF">{t('home.answered')} - {passedCount * 20}</Timeline.Item>
                            <Timeline.Item color="green">{t('home.true_answers')} - {trueAnswers}</Timeline.Item>
                            <Timeline.Item
                                color="red">{t('home.wrong_answers')} - {questions.length} {questions.length > 0 ?
                                <Button type={'link'}
                                        onClick={() => navigate('/wrongs')}>{t('home.answer')}</Button> : null}</Timeline.Item>
                            <Timeline.Item color="#00CCFF">{t('home.not_answered')}
                                - {1200 - (passedCount * 20)}</Timeline.Item>

                        </Timeline>
                    </Col>
                    <Col lg={12}>
                        <Card title={t('home.settings')}>
                            <Form labelCol={{lg: 12, sm: 12}} wrapperCol={{lg: 12, sm: 12}}>
                                <Form.Item label={t('home.see_answer_end')}
                                           extra={t('home.see_answer_end_desc')}>
                                    <Switch onChange={onSwitchChange} checked={settings.quick_view}/>
                                </Form.Item>
                                <Form.Item label={t('home.site_lang')}
                                           extra={'Հայերեն/Русский'}>
                                    <Switch checkedChildren="Հայ․" unCheckedChildren="Рус."
                                            checked={i18n.resolvedLanguage === 'hy'} onChange={handleLangChange}/>
                                </Form.Item>
                                <Form.Item label={'Սխալ պատասխաններ'}
                                           extra={'Մաքրել բոլոր սխալ պատասխանները'}>
                                    <Popconfirm title={'Բոլոր սխալ պատասխանները կհամարվեն ճիշտ վստահ եք?'}
                                                okText={'Այո'} cancelText={'Ոչ'}
                                                onConfirm={handleClearWrongs}><Button
                                        type={'danger'}>Մաքրել</Button></Popconfirm>
                                </Form.Item>
                                <Form.Item label={'Մաքրել արդյունքները'}
                                           extra={'Մաքրել բոլոր պատասխանված թեստերի արդյունքները'}>
                                    <Popconfirm title={'Բոլոր արդյունքները կմաքրվեն վստահ եք?'} okText={'Այո'}
                                                cancelText={'Ոչ'} onConfirm={handleClearAnswers}><Button
                                        type={'danger'}>Մաքրել</Button></Popconfirm>
                                </Form.Item>
                            </Form>

                        </Card>
                    </Col>
                </Row>
            </Card>
        </Col>
    </Row>)
}

export default Home;