import {Button, Image, Modal, Space, Card, Form, Input, notification} from "antd";
import {QuestionStats} from "./QuestionStats";
import {useEffect, useRef, useState} from "react";
import ReactGA from "react-ga";
import {Trans} from "react-i18next";
import api from "../../api";

function QuestionItem({currentQuestion, handleNext, value, noSubmit,quick_view,dispatch}) {
    const [selected,setSelected] = useState(value)
    const formRef = useRef();
    const handleShowDesc = ()=>{

        ReactGA.event({
            category: 'Question',
            action: 'Description',
            label: 'open'
        })

        Modal.info({
            content:<div >
                <div>{currentQuestion.description.desc_name}</div>
                {currentQuestion.description.desc_image?<Image src={process.env.REACT_APP_API_ENDPOINT + currentQuestion.description.desc_image}
                                                               alt={currentQuestion.description.desc_name}/>:null}
                <div>{currentQuestion.description.desc_text}</div>
                <Button onClick={handleShowNewDesc} type={'primary'} >Առաջարկել նոր բացատրություն</Button>
            </div>,

        })
    }
    const handleShowNewDesc = ()=>{
        Modal.confirm({
            title:'Բացատրությունը գրել հայատառ',
            onOk:(close)=>{
                formRef.current.submit();
                close()
            },
            content:<Form onFinish={handleSaveNewDef} ref={formRef}>
                <Form.Item label={'Ձեր անունը'} name={'title'} extra={'Որպեսզի ազգը ձեզ չմոռանա!!!'}>
                    <Input/>
                </Form.Item>
                <Form.Item label={'Բացատրության'} name={'description'} rules={[
                    {
                        message:'Դաշտը պարտադիր է լրացման համար',
                        required:true
                    }
                ]}>
                    <Input.TextArea/>
                </Form.Item>
            </Form>
        })
    }
    const handleSaveNewDef = (formData)=>{
        api.API.post(api.Question + `/${currentQuestion.id}/new-definition`,formData).then(()=>{
                notification.info({
                    description:'Ձեր գրառումը կստուգվի ադմինիստրատորի կողմից և կավելացվի ԱԶԳԸ ՁԵԶ ՉԻ ՄՈՌԱՆԱ'
                })

        })
    }
    let isSelected = (selected===0 || selected);
    useEffect(()=>{
        setSelected(value)
    },[value])
    const handleSelect = (key)=>{

        if(isSelected){
            return
        }
        if(quick_view){
            handleNext(key)
            return;
        }
        setSelected(key)
    }
    const handleConfirm = ()=>{
        setSelected(false)
        handleNext(selected)
    }
    return (
        <div className={'question-block'}>

            <h3>{currentQuestion.question}</h3>
            <QuestionStats currentQuestion={currentQuestion}/>
            <div className={'image-block'}>
                {currentQuestion.image && <Image
                    className={'image-item'}
                    alt={currentQuestion.question}
                    src={process.env.REACT_APP_API_ENDPOINT + currentQuestion.image+`?date=${ new Date().getDate()}`}
                />}
            </div>
            <Space direction={'vertical'} className={'quiz-variants-space'} style={{textAlign:'center'}}>
                {currentQuestion.variants.map((variant, key) => {
                    return <Card.Grid key={key} onClick={()=>handleSelect(key)} className={isSelected?`selected ${key===selected? selected===currentQuestion.answer?'selected-true':'selected-false':key===currentQuestion.answer?'selected-true':''}`:''}>{variant.value}</Card.Grid>
                })}
            </Space>
            <div className={'text-center mt-5'}>
                {currentQuestion.description
                && isSelected
                    ?<Button type={'danger'} onClick={handleShowDesc}><Trans i18nKey={'questions.see_description'}>Դիտել բացատրությունը</Trans></Button>:null}
            </div>
            {isSelected&& !noSubmit&&<div className={'text-center mt-5'}>
                <Button type={'primary'} onClick={handleConfirm}><Trans i18nKey={'questions.next'}>Հաջորդը</Trans></Button>
            </div>}
        </div>
    )
}

export default QuestionItem;
